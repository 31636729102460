/** @jsx jsx */
import React from 'react';
import { Global } from '@emotion/core';
import { Box, Container, jsx } from 'theme-ui';
import 'typeface-ibm-plex-sans';
import SEO from './seo';
import Header from './header';
import Footer from './footer';
import CodeStyles from '../styles/code';
import SkipNavLink from './skip-nav';

type LayoutProps = { children: React.ReactNode; className?: string };

const Layout = ({ children, className }: LayoutProps) => (
  <React.Fragment>
    <Global
      styles={(theme) => ({
        '*': {
          boxSizing: `inherit`,
        },
        html: {
          WebkitTextSizeAdjust: `100%`,
        },
        img: {
          borderStyle: `none`,
        },
        pre: {
          fontFamily: `monospace`,
          fontSize: `1em`,
        },
        '[hidden]': {
          display: `none`,
        },
        '::selection': {
          backgroundColor: theme.colors.text,
          color: theme.colors.background,
        },
        a: {
          transition: `all 0.3s ease-in-out`,
          color: `text`,
        },
      })}
    />
    <SEO />
    <SkipNavLink>Skip to content</SkipNavLink>
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Container
        sx={{
          // height: '100vh',
          flex: '1 0 auto',
        }}
      >
        <Header />
        <Box id='skip-nav' sx={{ ...CodeStyles }} className={className}>
          {children}
        </Box>
      </Container>
      <Footer />
    </div>
  </React.Fragment>
);

export default Layout;
