/** @jsx jsx */
import { jsx, Link, Container } from 'theme-ui';
import useSiteMetadata from '../hooks/use-site-metadata';

const Footer = () => {
  const { siteTitle } = useSiteMetadata();

  return (
    <Container>
      <footer
        sx={{
          maxWidth: `1024px`,
          flexShrink: 1,
          // m: [4],
          bottom: 0,
          boxSizing: `border-box`,
          display: `flex`,
          justifyContent: `space-between`,
          color: `secondary`,
          a: {
            variant: `links.secondary`,
          },
          flexDirection: [`column`, `column`, `row`],
          variant: `dividers.top`,
        }}
      >
        <div>
          &copy; {new Date().getFullYear()} by {siteTitle}. All rights reserved.
        </div>
        <div>
          {'theme by '}
          <Link
            aria-label='Original theme repository'
            href='https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-minimal-blog'
          >
            LekoArts
          </Link>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
